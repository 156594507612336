//  ==========================================================================
//  Handcrafted by Lawrence Allen using Sass
//  Last Updated On: 07.12.22
//  ==========================================================================

//  Table of Contents
//  01. Typography
//      - Imports
//      - Rendering
//      - Ligatures
//      - Body Copy
//      - Headings
//      - Links

//  ==========================================================================
//  01. Typography
//  ==========================================================================

// * 01.a - Imports

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,700;1,400&display=swap');


// * 01.b - Rendering

html,
input,
textarea {-webkit-font-smoothing:antialiased;}

// * 01.c - Ligatures

body {
    text-rendering:optimizeLegibility; // Turns on ligatures & kerning
    -moz-font-feature-settings:"liga=0, dlig=0";
    -ms-font-feature-settings:"liga" 0, "dlig" 0;
    -o-font-feature-settings:"liga" 0, "dlig" 0;
    -webkit-font-feature-settings:"liga" 0, "dlig" 0;
    font-feature-settings:"liga" 0, "dlig" 0; // For more refined (but less supported) ligature control
    -moz-osx-font-smoothing: grayscale; // Better Font Rendering
}

// * 01.d - Body Copy

body {
    color: $color-base;
    font-family: 'Roboto', Helvetica, sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 40px;

  @include breakpoint(dark-knight) {
    font-size: 16px;
    line-height: 24px;
  }
}

// * 01.e - Headings
h1,h2,h3,h4,h5,h6 {
  margin: 0;
}

h1,h2, label {
  font-family: 'Roboto Condensed', Helvetica, sans-serif;
  font-style: normal;
  font-weight: 700;
}

h3,h4 {
  font-family: 'Roboto', Helvetica, sans-serif;
  font-weight: 900;
}

h1 {
  font-size: 40px;
  line-height: 48px;
}

h1.display {
  font-size: 152px;
  line-height: 136px;
  text-align: center;
  text-transform: uppercase;

  @include breakpoint(dark-knight-rises) {
    font-size: 125px;
    line-height: 125px;
  }

  @include breakpoint(dark-knight) {
    font-size: 96px;
    line-height: 1;
  }

  @include breakpoint(batman-begins) {
    font-size: 56px;
    line-height: 1;
  }
}

h2 {
  font-size: 32px;
  line-height: 40px;
}

h3 {
  font-size: 24px;
  line-height: 32px;
}

h4 {
  font-size: 20px;
  line-height: 24px;
}

// * 01.f - Links

a {
  color: $color-accent;
}
