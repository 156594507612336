//  ==========================================================================
//  Handcrafted by Lawrence Allen using Sass
//  Last Updated On: 07.10.22
//  ==========================================================================

//  Table of Contents
//  00. Sensible Defaults
//      - Imports
//  01. Globals & General Cases
//      - Globals
//      - Generals
//      - Buttons
//  02. Header
//  03. Navigation
//  04. Footer
//  05. Sections
//    - Hero
//    - Statement
//    - Mission
//    - Values
//    - About Academy
//    - About Therapeutics
//    - Activities
//    - Quote
//    - Trailing CTA

//  ==========================================================================
//  00. Sensible Defaults
//  ==========================================================================

a {
  text-decoration: none;
}

// * 00.a - Imports

@import 'normalize';
@import 'base';
@import 'typography';

//  ==========================================================================
//  01. Globals & General Cases
//  ==========================================================================

// * 01.a - Globals

*, *:before, *:after { box-sizing: border-box; }

article,
button,
div,
footer,
header,
img,
li,
nav,
p,
section,
span,
ul {
  border: 0; // Setting elements box-model to zero
  position: relative; // For absolutely positioning elements within containers
  margin: 0; // Setting elements box-model to zero
  padding: 0; // Setting elements box-model to zero
}

img { display: inline-block; max-width: 100%; } // For clearing invisible padding

// * 01.b - Generals
html {
  background: $color-secondary;
}

.wrapper {
  margin: 0 auto;
  max-width: 1200px;
  width: 100%;

  @include breakpoint(dark-knight) {
    max-width: 900px;
    width: 100%;
  }
}

// * 01.c - Buttons

button {
  background: $color-accent;
  border: 4px solid rgba(255, 255, 255, 0.15);
  box-shadow: 0px 4px 6px -1px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.1);
  border-radius: 8px;
  color: $color-base;
  font-family: 'Roboto', Helvetica, sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  padding: 20px 56px;
  text-transform: uppercase;
}

button:hover {
  background: $color-quaternary;
  box-shadow: none;
}

//  ==========================================================================
//  02. Header
//  ==========================================================================

header {
  background: rgba(23, 48, 41, 0.75);
  line-height: 0; // For removing hidden element extra height
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
}

//  ==========================================================================
//  03. Navigation
//  ==========================================================================

nav {
  align-items: center;
  display: inline-flex;
  justify-content: space-between;
  margin: 0 auto;
  padding: 32px 80px;
  width: 100%;

  @include breakpoint(dark-knight) {
    padding: 16px 20px;
  }
}

nav .logo svg {
  height: 96px;
  width: 100%;

  @include breakpoint(batman-begins) {
    height: 60px;
  }
}

nav {
  ul {
    list-style: none;
    padding: 0;

    @include breakpoint(dark-knight) {
      display: none;
    }
  }

  @include breakpoint(dark-knight) {
    .nav-open {
      display: block;
    }

    .nav-close {
      display: none;
      font-size: 32px;
    }

    &.expand-mobile-nav {
      align-items: flex-start;
      background: $color-tertiary;
      flex-direction: row;
      flex-wrap: wrap;

      .nav-open {
        display: none;
      }

      .nav-close {
        display: block;
      }

      ul {
        display: flex;
        flex-direction: column;
        height: 100vh;
        justify-content: center;
        order: 1;
        width: 100%;

        li {
          margin: 0;
          padding: 16px;
          width: 100%;
        }
      }
    }
  }

  .nav-toggle {
    display: none;
    cursor: pointer;

    @include breakpoint(dark-knight) {
      align-items: center;
      display: flex;
      height: 60px;
    }
  }
}

nav ul li {
  display: inline-block;
  margin: 0 20px;
  text-align: center;
}

nav ul li:last-of-type {
  margin-right: 0;
}

nav li a {
  color: $color-base;
  font-family: 'Roboto', Helvetica, sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
}

nav li a:hover {
  text-decoration: underline;
}

nav button {
  padding: 12px 28px;
}

//  ==========================================================================
//  04. Footer
//  ==========================================================================

footer {
  background: $color-tertiary;
  padding: 120px 0 96px 0;
  text-align: center;

  @include breakpoint(batman-begins) {
    padding: 80px 20px;
  }
}

footer .wrapper {
  max-width: 790px;
}

footer h1 {
  margin-bottom: 32px;
}

footer .text {
  margin-bottom: 80px;

  @include breakpoint(batman-begins) {
    margin-bottom: 48px;
  }
}

footer svg {
  margin-bottom: 96px;
}

footer form {
  margin-bottom: 96px;
}

footer label {
  color: $color-base;
  text-transform: uppercase;

  @include breakpoint(dark-knight) {
    font-size: 16px;
  }
}

footer fieldset {
  padding: 0 0 40px 0;
  text-align: left;
}

footer fieldset {
  border: none;
}


footer fieldset,
footer input,
footer textarea {
  margin: 0;
  width: 100%;
}

footer input,
footer textarea {
  border: 4px solid $color-senary;
  border-radius: 8px;
  background-color: $color-base;
}

footer input {
  height: 70px;
  padding: 20px;
}

footer textarea {
  height: 300px;
  padding: 20px;
  resize: vertical;
}

footer button {
  width: 100%;
}

.legal {
  font-size: 16px;
  line-height: 24px;
}

//  ==========================================================================
//  05. Sections
//  ==========================================================================

// * 05.a - Hero

#hero {
  align-items: center;
  background-image:
    url('../images/mapLines_hero.png'),
    url('../images/background_hero.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  height: 100vh;
  justify-content: center;
  text-align: center;

  @include breakpoint(batman-begins) {
    background-position: center, 81%;
    padding: 0 20px;
  }
}

#hero h1 {
  text-shadow: 0px 20px 25px rgba(16, 24, 40, 0.1), 0px 8px 10px rgba(16, 24, 40, 0.1);
  word-break: break-word;
  margin-top: 160px;

  @include breakpoint(batman-begins) {
    margin-top: 60px;
  }
}

#hero .text p {
  margin: 48px auto;
  max-width: 790px;
  text-align: center;

  @include breakpoint(batman-begins) {
    margin: 32px auto;
  }
}

#hero button {
  @include breakpoint(batman-begins) {
    padding: 20px 0;
    width: 100%;
  }
}

// * 05.b - About

#about {
  border-bottom: 10px solid $color-senary;
  border-top: 10px solid $color-senary;
  background: #F8F8F8 url('../images/background-texture.png') no-repeat center;
  background-size: cover;
  color: $color-quinary;
  padding-bottom: 160px;

  @include breakpoint(batman-begins) {
    padding-bottom: 80px;
  }
}

#about .text p {
  margin: 32px 0;
}

// * 05.c - Statement

.statement {
  margin: 0 auto;
  max-width: 1000px;
  padding: 160px 0 240px 0;
  text-align: center;

  @include breakpoint(dark-knight) {
    max-width: 800px;
    padding: 80px 0 120px;
  }

  @include breakpoint(batman-begins) {
    padding: 80px 20px;
  }
}

.statement h1 {
  @include breakpoint(batman-begins) {
    font-size: 24px;
    line-height: 32px;
  }
}

// * 05.d - Mission
#about .container {
  background: linear-gradient(to right, $color-quaternary 35%, transparent 35%);
  margin-bottom: 240px;
  padding: 80px 0;

  @include breakpoint(dark-knight) {
    padding: 32px 0 0 0;
  }

  @include breakpoint(batman-begins) {
    background: linear-gradient(to bottom, $color-quaternary 32px, transparent 35%);
    margin-bottom: 80px;
    padding: 32px 0 0 0;
  }
}

#about .mission {
  @include breakpoint(batman-begins) {
    flex-direction: column;
  }
}

#about .mission,
#about .values {
  align-items: center;
  display: flex;
  gap: 11.25%;

  @include breakpoint(dark-knight) {
    gap: 6%;
  }
}

#about .mission img,
#about .values img {
  line-height: 1;
  max-width: 481px;

  @include breakpoint(dark-knight) {
    margin-bottom: 32px;
  }

  @include breakpoint(batman-begins) {
    max-height: 375px;
    max-width: 100%;
    object-fit: cover;
    width: 100%;
  }
}

.mission .img img:first-of-type {
  max-width: 320px;
  position: absolute;
  right: 360px;
  top: 320px;
  z-index: 1;

  @include breakpoint(batman-begins) {
    display: none;
  }
}

.mission .img img:last-of-type {
  @include breakpoint(batman-begins) {
    width: 100%;
  }
}

.mission .text,
.values .text {
  @include breakpoint(batman-begins) {
    padding: 0 20px;
    width: 100%;
  }
}

.mission .text p,
.values .text p {
  @include breakpoint(dark-knight) {
    font-size: 16px;
    line-height: 24px;
  }
}

.mission ul {
  font-family: 'Roboto', Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  list-style: none;
  text-transform: uppercase;
}

#about .mission li a {
  align-items: center;
  color: $color-quinary;
  display: inline-flex;
  gap: 24px;
  padding: 16px 0;

  @include breakpoint(dark-knight) {
    padding-top: 0;
  }

  @include breakpoint(dark-knight) {
    justify-content: space-between;
    width: 100%;
  }
}

#about .mission li:first-of-type a {
  padding-top: 0;

  @include breakpoint(dark-knight) {
    border-bottom: 1px solid $color-senary;
  }
}

#about .mission li:last-of-type a {
  padding-bottom: 0;
  padding-top: 16px;
}

#about .mission li a img {
  margin: 0;
  width: 56px;

  @include breakpoint(dark-knight) {
    width: 32px;
  }
}

// * 05.e - Values

#about .container:last-of-type {
  background-image: url('../images/background-watermark1.png');
  background-position: right center;
  background-repeat: no-repeat;
  margin-bottom: 0;
  padding-bottom: 0;

  @include breakpoint(batman-begins) {
    background-position: right top;
    padding-top: 0;
  }
}

.values {
  @include breakpoint(batman-begins) {
    flex-direction: column-reverse;
  }
}

.values ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.values li {
  background: url('../images/check.svg') no-repeat;
  padding: 0 0 16px 48px;

  @include breakpoint(dark-knight) {
    font-size: 16px;
    line-height: 24px;
  }

  @include breakpoint(batman-begins) {
    padding: 7px 0 24px 48px;
  }
}

.mission .img,
.values .img {
  @include breakpoint(batman-begins) {
    line-height: 1;
    width: 100%;
  }
}

// * 05.f - About Academy & About Therapeutics

#aboutAcademy .wrapper,
#aboutTherapeutics .wrapper {
  display: flex;
  gap: 135px;

  @include breakpoint(dark-knight) {
    gap: 6%;
  }
}

#aboutAcademy .wrapper {
  @include breakpoint(batman-begins) {
    flex-direction: column-reverse;
  }
}

#aboutTherapeutics .wrapper {
  @include breakpoint(batman-begins) {
    flex-direction: column;
  }
}

#aboutAcademy .text,
#aboutTherapeutics .text {
  position: relative;
  width: 65%;
  z-index: 1;

  @include breakpoint(dark-knight) {
    margin-bottom: 48px;
  }

  @include breakpoint(batman-begins) {
    width: 100%;
  }
}

#aboutAcademy .text p,
#aboutTherapeutics .text p {
  margin: 32px 0 0 0;
}

#aboutAcademy .text svg,
#aboutTherapeutics .text svg {
  position: absolute;
  left: -24px;
  top: -24px;
  z-index: 0;
}

#aboutAcademy .text h1,
#aboutTherapeutics .text h1 {
  position: relative;
  z-index: 1;
}

.cards {
  display: flex;
  flex-direction: column;
  gap: 32px;

  @include breakpoint(batman-begins) {
    gap: 16px;
  }
}

#aboutAcademy .card,
#aboutTherapeutics .card {
  background: $color-base;
  border: 10px solid $color-senary;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px -1px rgba(16, 24, 40, 0.1);
  color: $color-quinary;
  padding: 30px;

  @include breakpoint(batman-begins) {
    padding: 22px;
  }
}

.card .heading {
  align-items: center;
  display: inline-flex;
  gap: 8px;
  margin-bottom: 16px;

  @include breakpoint(batman-begins) {
    margin-bottom: 8px;
  }
}

.card .heading svg {
  @include breakpoint(batman-begins) {
    height: 100%;
    width: 40px;
  }
}

.card p {
  margin: 0;
}

#aboutAcademy {
  background:
    url('../images/mapLines_aboutAcademy.png') right center no-repeat,
    url('../images/background_about.jpg') no-repeat;
  background-size: auto, cover;
  padding: 280px 0 544px 0;

  @include breakpoint(dark-knight) {
    padding: 140px 0;
  }

  @include breakpoint(batman-begins) {
    padding: 80px 20px;
  }
}

#aboutTherapeutics {
  background: url('../images/mapLines_aboutTherapeutics.png') right center no-repeat;
  background-size: cover;
  padding: 280px 0;

  @include breakpoint(dark-knight) {
    padding: 80px 20px;
  }
}

// * 05.g - Activities

#activities {
  background: url('../images/background_activities.jpg') left center no-repeat;
  background-size: cover;
  padding: 160px 0;

  @include breakpoint(dark-knight) {
    padding: 120px 0;
  }

  @include breakpoint(batman-begins) {
    padding: 80px 20px;
  }
}

#activities .text {
  color: $color-quinary;
  margin-bottom: 80px;

  @include breakpoint(batman-begins) {
    margin-bottom: 48px;
  }
}

#activities .text h1 {
  margin-bottom: 32px;
}

#activities .cards {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 32px;

  @include breakpoint(dark-knight) {
    grid-template-columns: auto;
    grid-gap: 8px;
  }
}

#activities .card {
  background: $color-senary;
  box-shadow: 0px 20px 25px -5px rgba(16, 24, 40, 0.1), 0px 8px 10px -6px rgba(16, 24, 40, 0.1);
  color: $color-quinary;
  padding: 32px;

  @include breakpoint(dark-knight) {
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: row-reverse;
    gap: 16px;
    padding: 20px;
  }
}

#activities .card h4 {
  margin-bottom: 16px;

  @include breakpoint(dark-knight) {
    margin-bottom: 0;
    width: 100%;
  }
}

#activities .card p {
  margin-bottom: 24px;

  @include breakpoint(dark-knight) {
    display: none;
  }
}

#activities .card .img {
  background: rgba(255, 255, 255, 0.15);
  padding: 40px;
  width: 100%;

  @include breakpoint(dark-knight) {
    display: inline-flex;
    padding: 8px;
    width: auto;
  }
}

#activities .img svg {
  @include breakpoint(dark-knight) {
    height: 32px;
    width: 32px;
  }
}

// * 05.h - Quote

.quote {
  background:
    url('../images/background-watermark2.png') no-repeat center bottom,
    url('../images/background-texture.png') no-repeat center;
  background-color: #F8F8F8;
  background-size: 100%, cover;
  color: $color-quinary;
  padding: 200px;

  @include breakpoint(batman-begins) {
    padding: 80px 20px;
  }
}

.quote p {
  font-family: 'Roboto Condensed', Helvetica, sans-serif;
  font-size: 40px;
  font-style: italic;
  font-weight: 400;
  line-height: 48px;

  @include breakpoint(batman-begins) {
    font-size: 24px;
    line-height: 32px;
  }
}

// * 05.i - Trailing CTA

.trailingCTA {
  background: url('../images/mapLines_aboutTherapeutics.png') right center no-repeat;
  background-size: auto;
  border-width: 40px 0px;
  border-style: solid;
  border-color: rgba(23, 48, 41, 0.1);
  color: $color-tertiary;
  padding: 160px 0;

  @include breakpoint(dark-knight) {
    padding: 80px 20px;
  }
}

.trailingCTA .text {
  @include breakpoint(dark-knight) {
    margin-bottom: 32px;
  }
}

.trailingCTA .text h1 {
  position: relative;
  z-index: 1;

  @include breakpoint(dark-knight) {
    margin-bottom: 32px;
  }
}

.trailingCTA .text svg {
  position: absolute;
  left: -24px;
  top: -24px;
  z-index: 0;
}

.trailingCTA .wrapper {
  align-items: center;
  display: flex;

  @include breakpoint(dark-knight) {
    flex-direction: column;
  }
}

.trailingCTA button {
  white-space: nowrap;

  @include breakpoint(batman-begins) {
    padding: 20px 0;
    width: 100%;
  }
}
