//  ==========================================================================
//  Handcrafted by Lawrence Allen using Sass
//  Last Updated On: 07.12.22
//  ==========================================================================

//  Table of Contents
//  01. Variables
//    - Palette
//  02. Placeholder Selectors
//  03. Mixins

//  ==========================================================================
//  01. Variables
//  ==========================================================================

// * 01.a - Palette

$color-base: #FFFFFF;             // White
$color-secondary: #91996E;        // Artichoke
$color-tertiary: #173029;         // Jungle Green
$color-accent: #B75D2A;           // Ruddy Brown
$color-quaternary: #DD773D;       // Orange
$color-quinary: #676359;          // Dark Brown
$color-senary: #E8E4D9;           // Light Brown

//  ==========================================================================
//  02. Placeholder Selectors
//  ==========================================================================

//  ==========================================================================
//  03. Mixins
//  ==========================================================================

$batman-begins: 1000px;
$dark-knight: 1250px;
$dark-knight-rises: 1400px;

@mixin breakpoint($point) {
    @if $point == batman-begins {
        @media (max-width: $batman-begins) { @content; }
    }
    @if $point == dark-knight {
        @media (max-width: $dark-knight) { @content; }
    }
    @if $point == dark-knight-rises {
        @media (max-width: $dark-knight-rises) { @content; }
    }
}

@mixin group {
    &:before, &:after { content:""; display:table; }
    &:after { clear:both; }
    @if $legacy-support-for-ie7 or $legacy-support-for-ie6 {
        zoom:1;
    }
}

$debug: false;

@if $debug {
    .breakpoint-helper {
        position: fixed;
        left: 0;
        bottom: 0;
        padding: 0 10px;
        border-radius: 0 5px 0 0;
        z-index: 1001;

        white-space: nowrap;

        background: #ffdcb2;
        color: #222;
        span:before {
            content: 'Bruce Wayne';
        }

        @include breakpoint(batman-begins) {
            background: #333;
            color: yellow;

            span:before {
                content: 'Batman Begins';
            }
        }
        @include breakpoint(dark-knight) {
            background: black;
            color: whitesmoke;

            span:before {
                content: 'Dark Knight';
            }
        }
        @include breakpoint(dark-knight-rises) {
            background: whitesmoke;
            color: #333;

            span:before {
                content: 'Dark Knight Rises';
            }
        }
    }
}
